$spacing: 0.7rem;

.table {  
  // spacing left & right in rows and headers
  th,
  td {
    &:first-child {
      padding-left: $spacing !important;
    }
    &:last-child {
      padding-right: $spacing !important;
    }
  }
}
